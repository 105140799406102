<template>
  <b-container class="mt-4">
    <b-form @submit="onSubmit">
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="title" class="mr-2 text-white"></label>
          <b-button variant="dark" class="mr-2" to="/messages">
            Mesajlar
          </b-button>
          <b-button variant="primary" type="submit">Gönder</b-button>
        </template>
        <b-alert class="spacing" show>
          Bilgilerinizi girin ve <b>Gönder</b> butonuna tıklayın.
        </b-alert>
        <b-alert
          v-if="hasRole(['federation'])"
          class="spacing"
          variant="success"
          show
        >
          Göndermek istediğiniz kişinin isminin en az 3 harfini yazın ve
          gönderilecek personeli sağ taraftan seçin.
        </b-alert>
        <b-row>
          <b-col :cols="hasRole(['federation']) ? 8 : 12">
            <InputTile
              v-if="hasRole(['federation'])"
              label="Ara"
              :custom="true"
            >
              <b-form-input :formatter="getPersonel"></b-form-input>
            </InputTile>
            <InputTile label="Konu" v-model="form.title" :required="true" />
            <InputTile label="Mesaj" :custom="true">
              <b-textarea
                v-model="form.message"
                rows="5"
                max-rows="5"
              ></b-textarea>
            </InputTile>
          </b-col>
          <b-col cols="4" v-if="hasRole(['federation'])">
            <b-form-select
              v-model="form.to"
              :options="getPersonelOptions"
              :select-size="11"
            >
            </b-form-select>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import InputTile from "./common/InputTile.vue";
import { mapGetters } from "vuex";
import cities from "@/store/constants/cities";
export default {
  components: { InputTile },
  computed: {
    ...mapGetters(["getMessagePersonel"]),
    getPersonelOptions: {
      get() {
        return this.getMessagePersonel.map((e) => {
          return {
            value: e.id,
            text:
              e.name +
              " " +
              e.surname +
              " (" +
              this.cities.filter(
                (a) => a.value == e.contact.cityWhereLocated
              )[0].text +
              ")",
          };
        });
      },
    },
  },
  data() {
    return {
      form: {
        to: 0,
        title: "",
        message: "",
        hasRead: 0,
      },
      cities,
    };
  },
  props: ["title", "submit"],
  created() {
    this.$store.dispatch("initManagement");
    this.$store.commit("updateMessagePersonel", []);
  },
  methods: {
    getPersonel(val) {
      if (val.length > 2) {
        this.$store.dispatch("getMessagePersonelFromServer", {
          where: val,
        });
      }
      return val;
    },
    onSubmit(event) {
      event.preventDefault();
      this.form.from = this.$store.getters.getUser.id;
      this.submit(this.form);
    },
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        }
      });
      return vals.includes(true);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.buttonBar {
  text-align: end;
}
.center {
  text-align: center;
}
.labelContainer {
  display: grid;
  place-items: left;
}
.spacing {
  margin-top: 0.5rem;
}
</style>