<template>
  <MessageForm v-bind="props" />
</template>

<script>
import MessageForm from "@/components/forms/MessageForm.vue";
export default {
  components: { MessageForm },
  data() {
    return {
      props: {
        title: "Yeni Mesaj",
        submit: (form) => {
          this.$store
            .dispatch("sendMessage", form)
            .then(() => this.$router.push("/messages"));
        },
      },
    };
  },
};
</script>

<style scoped>
</style>